<template>
  <div>
    <v-card
      elevation='3'
      outlined
      width='450'
      height='450'
    >
      <v-container fluid justify='center' align='center' class='h-100'>
        <v-row justify='center' align='center' class='my-5'>
          <v-img contain max-width='250' max-height='100' :src='logoUrl || openbioLogoUrl' />
        </v-row>

        <v-row no-gutters>
          <v-card-text>
            <v-form v-model='formIsValid' lazy-validation>
              <v-text-field
                v-model='user.login'
                :rules='[rules.required]'
                :label='`${$t("EMAIL")} / ${$t("CPF")}`'
                @keyup.enter='signin'
              />
              <v-text-field
                v-model='user.password'
                :rules='[rules.required]'
                :type='showPassword ? "text" : "password"'
                :append-icon='showPassword ? "mdi-eye" : "mdi-eye-off"'
                :label='$t("PASSWORD")'
                @click:append='togglePasswordVisibility'
                @keyup.enter='signin'
              />
            </v-form>

            <v-btn block color='primary' class='mt-8' @click='signin'>
              {{$t('SIGNIN')}}
            </v-btn>
          </v-card-text>
        </v-row>
        <v-row v-if='logoUrl' justify='center' align='center' class='mt-4'>
          <v-img contain max-width='100' max-height='50' :src='openbioLogoUrl' />
        </v-row>
      </v-container>
    </v-card>

    <v-card
      flat
      dark
      width='450'
      max-height='450'
      style='margin-top: 15px; background: none;'
    >
      <v-row no-gutters class='h-100' align='center' justify='center'>
        <span>
          {{$t('FORGOT_YOUR_PASSWORD')}}
          <v-btn text @click='toggleRecoverPassword'>
            {{$t('RECOVER_PASSWORD')}}
          </v-btn>
        </span>
      </v-row>
    </v-card>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mapActions, mapMutations, mapGetters } from 'vuex';

  export default {
    name: 'SignIn',
    data: function () {
      return {
        showPassword: false,
        rules: {
          required: (value) => !!value || this.$t('REQUIRED'),
        },
        formIsValid: true,
        user: {
          login: undefined,
          password: undefined,
        },
      };
    },
    computed: {
      ...mapGetters('customTheme', ['logoUrl', 'openbioLogoUrl']),
    },
    methods: {
      ...mapMutations('customTheme', ['setLogoUrl', 'setThemeColors']),
      ...mapMutations('auth', ['setUser']),
      ...mapActions('auth', ['getLoggedUser']),
      togglePasswordVisibility: function () {
        this.showPassword = !this.showPassword;
      },
      toggleRecoverPassword: function () {
        this.$emit('toggle-recover-password');
      },
      signin: async function () {
        if (!this.formIsValid) {
          return;
        }

        try {
          const { data } = await axios({
            url: '/account-sign-in',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            data: {
              email: this.user.login,
              password: this.user.password,
            },
            withCredentials: true,
          });

          this.$store.commit('auth/setUser', {
            account: data.account,
            person: data.person,
          });
          await this.getLoggedUser();
          this.setThemeColors(data.account.customerTheme);
          this.setLogoUrl(data.account.customerTheme.logo);
          await this.$store.dispatch('auth/getLoggedUser');
          this.$emit('signin-in-success');
          this.$store.dispatch('auth/toggleSignInPageVisibility', { value: false });
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
    },
  };
</script>
